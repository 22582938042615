module.exports = {

    wakuang1: {
        wodezhanghao: 'حسابي',
        tuijianren: 'المشاركة',
        fengxiang: 'مشاركة',
        dengjijindu:'تقدم المستوى',
        dangqiandengji:'المستوى الحالي',
        haixu:'مازال مطلوبًا',
        shengji:'التحديث',
        wodedaibi: 'توكني',
        wodeVCD: 'VCD الخاص بي',
        zongji:'المجموع',
        jiazhi:'القيمة',
        wodekuangchi: 'حوض التعدين',
        wodesuanli: 'قوة الحوسبة',
        yilingqujiangli: 'تم المطالبة بها بالفعل (U)',
        wodeshouyi: 'أرباحي',
        jiasushouyi: 'أرباح متسارعة',
        zongji: 'المجموع (VCD)',
        jiazhi:'القيمة (U)',
        zongkuangchi:'المجموع حوض التعدين',
        chongzhinengliang: 'شحن الطاقة',
        wodetuandui: 'فريقي',
        wodezhitui: 'الترويج المباشر',
        zhishuxiashu: 'الموظفين التابعين',
        dengji:'المستوى',
        renshu:'الأداء',
        jine: 'الكمية',
        jianglimingxi: 'تفاصيل المكافأة',
        VCDlingqu: 'طلب VCD',
        shuliang: 'الكمية',
        shijian: 'الوقت',
        dangqianjiage: 'السعر الحالي',
    },
    wakuang2: {
        duihuan: 'تبادل',
        jishijiaoyidaibi: 'توكن التداول الفوري',
        yue: 'الرصيد',
        huadianshangxian: 'حد الانزلاق',
        jiaoyi: 'معاملة',
    },
    guanwang1: {
        us1: "منصة التمويل اللامركزية الرائدة في العالم",
        us2: 'عقد',
        us3: 'انضم إلى الشركة التابعة',
        us4: 'هدفنا',
        us5: 'اختر صناديق التداول في البورصة كأداة رافعة مالية مع رأس مال أولي أقل',
        us6:'·تعدين VCD',
        us7:'أغسطس 2024',
        us8: '·تواصل IM',
        us9: 'نوفمبر 2024',
        us10: '·شبكة الاختبار العامة لـ VCD',
        us11: 'مايو 2025',
        us12: '·شبكة VCD الرئيسية',
        us13: 'أغسطس 2025',
        us14:'·نظام بيئي VCD',
        us15:'ديسمبر 2025',
        us16: 'اقتصاد توكن VCD',
        us17: 'توزيع التوكن',
        us18: 'العرض الكلي',
        us19: 'التعدين',
        us20:'السيولة',
        us21:'الأداء',
        us22: 'لماذا نطور شبكة VCD العامة',
        us23: 'لتمكين VCD من التنفيذ على الشبكة، což سيكون أكثر انفتاحًا وعدالة',
        us24: 'هل يمكن لـ VCD أن يتم تنفيذها على الشبكات العامة الأخرى',
        us25: 'يمكن تحقيق ذلك. ومع ذلك، بسبب خصائص آلية الشبكات العامة الحالية، سيتم المضاربة بها من قبل العقد',
        us26: 'الفرق بين شبكة VCD العامة وشبكة EVM العامة',
        us27: 'عدل آلية GAS لضمان عدم قدرة العقد على الانتظار مسبقًا، مما يجعل التمويل أكثر عدالة وأمانًا',
        us28: 'السبب في عدم تنفيذ VCD على الشبكة العامة',
        us29: 'آلية الانتظار الحالية لـ EVM تسمح للعقد بالانتظار في الشبكة الداخلية، مما يمكن من المضاربة بنسبة 100٪',
        us30: 'تواصل معنا',
    },
    guanwang2: {
        us111: 'الصفحة الرئيسية',
        us222: 'ورقة بيضاء',
        us333: 'مؤسسة',
        us444: 'ترويج',
        us555: 'تعدين',
        us666: 'تواصل معنا',
        us777: 'نجحت النسخ',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'توظيف شركاء العقد الفائقين',
    wodedizhi: 'عنواني',
    wodezhanghao: 'حسابي',
    shouquandizhi: 'عنوان الإذن',
    shifoushichaojijiedian: 'هل هو عقد فائق',
    shichaojijiedian:'هو عقد فائق',
    bushichaojijiedian:'ليس هو عقد فائق',
    dianjijiaru: 'انقر للانضمام',
},
    xuanfa: {
    baocuntupian:'حفظ الصورة',
    baocunchenggong:'نجحت الحفظ',
},
    tishi: {
    us888:'نجحت الإعداد',
    us999:'يرجى الاتصال بالمحفظة',
    us1111:'نجحت النسخ',
    us2222:'إلغاء العملية',
    us3333:'لا يمكنك الارتباط بنفسك!',
    us4444:'يرجى الاتصال بالمحفظة أولاً',
    us5555:'يرجى إدخال العنوان الصحيح',
    us6666:'نجحت الاشتراك!',
    us7777:'العنوان الحالي هو بالفعل عقد فائق!',
    us8888:'يرجى إدخال عنوان صالح',
    us9999:'نجحت التبادل',
    us11111:'الارتباط...',
    us22222:'الإذن...',
    us33333:'الدفع...',
    },
    yilou: {
        us44444: 'إيراداتي (مجمدة)',
        us55555: 'جاري التحديث...',
        us66666: 'جاري الاخذ...',
        us77777: 'جاري الانضمام...',
        us88888: 'جاري الصرف...',
        us99999: 'تأكيد',
        us111111: 'إلغاء'
    }
}