<template>
    <div class="padding-top__20px">
        <div v-if="pageType == 1" class="Swap">
            <van-row>
                <van-row class="display-flex__align-center__justify-between margin-bottom__10px">
                    <van-col :span="6" class="font-family-Text font-color__theme font-size__16px">
                        USDT
                    </van-col>
                    <van-col :span="24 - 6" class="text-align__right font-size__12px">
                        {{ $t(`wakuang2.yue`) }}: <span class="font-family-Number font-size__14px">{{ pageConfig['USDTyue'].value }}</span>
                    </van-col>
                </van-row>
                <van-col :span="24" class="position__relative">

                    <van-field class="content-approve-address padding__10px" type="number" v-model="model.userUSDT"
                        :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                        @input="handleUSDT">

                    </van-field>

                    <div class="data-config">
                        <div v-for="(item, index) of configList" :key="index" class="cell" @click="ComputedNumber(item)">
                            {{ item }}%
                        </div>
                    </div>
                </van-col>
            </van-row>

            <div class="cut-image display-flex__align-center__justify-center padding__10px">
                <img style="height: 30px;" :src="imgList.jiantou_icon" alt="" @click="Cut">
                
                <img style="height: 30px;" class="img-sub" :src="imgList.jiantou_icon" alt="" @click="Cut">
            </div>


            <van-row>
                <van-row class="display-flex__align-center__justify-between margin-bottom__10px">
                    <van-col :span="6" class="font-family-Text font-color__theme font-size__16px">
                        VCD
                    </van-col>
                    <van-col :span="24 - 6" class="text-align__right font-size__12px">
                        {{ $t(`wakuang2.yue`) }}: <span class="font-family-Number font-size__14px">{{ pageConfig['VCDyue'].value }}</span>
                    </van-col>
                </van-row>
                <van-col :span="24" class="position__relative">

                    <van-field class="content-approve-address content-approve-address__disabled padding__10px" type="number"
                        v-model="model.userVCD" :disabled="true"
                        :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }">

                    </van-field>
                </van-col>
            </van-row>

            <van-row class="display-flex__align-center__justify-between">
                <van-col :span="16">
                    {{ $t(`wakuang2.huadianshangxian`) }} 
                    ({{ model.minamount }})
                </van-col>
                <van-col :span="24 - 16">
                    <!-- <input v-model="model.skew" type="text" minlength="1" @blur="handleSkew('userVCD')"> -->
                    <van-field class="content-skew" type="number" v-model="model.skew" center placeholder="" @input="handleSkew('userVCD')">
                        <template #button>
                            <!-- <van-icon name="cross" /> -->
                            <span class="font-color__white">%</span>
                        </template>
                    </van-field>
                </van-col>
            </van-row>

            <div class="display-flex__align-center__justify-center width__100per padding-top__10px">
                <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                    @click="abiFunUSDTToVCD">
                    <div class="font-color__theme-glod">
                        {{ $t(`wakuang2.jiaoyi`) }}
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="Swap">
            <van-row>
                <van-row class="display-flex__align-center__justify-between margin-bottom__10px">
                    <van-col :span="6" class="font-family-Text font-color__theme font-size__16px">
                        VCD
                    </van-col>
                    <van-col :span="24 - 6" class="text-align__right font-size__12px">
                        {{ $t(`wakuang2.yue`) }}: <span class="font-family-Number font-size__14px">{{ pageConfig['VCDyue'].value }}</span>
                    </van-col>
                </van-row>
                <van-col :span="24" class="position__relative">

                    <van-field class="content-approve-address padding__10px" type="number" v-model="model.userVCD"
                        :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                        @input="handleVCD">

                    </van-field>

                    <div class="data-config">
                        <div v-for="(item, index) of configList" :key="index" class="cell" @click="ComputedNumber(item)">
                            {{ item }}%
                        </div>
                    </div>
                </van-col>
            </van-row>

            <div class="cut-image display-flex__align-center__justify-center padding__10px">
                <img style="height: 30px;" :src="imgList.jiantou_icon" alt="" @click="Cut">
                
                <img style="height: 30px;" class="img-sub" :src="imgList.jiantou_icon" alt="" @click="Cut">
            </div>


            <van-row>
                <van-row class="display-flex__align-center__justify-between margin-bottom__10px">
                    <van-col :span="6" class="font-family-Text font-color__theme font-size__16px">
                        USDT
                    </van-col>
                    <van-col :span="24 - 6" class="text-align__right font-size__12px">
                        {{ $t(`wakuang2.yue`) }}: <span class="font-family-Number font-size__14px">{{ pageConfig['USDTyue'].value }}</span>
                    </van-col>
                </van-row>
                <van-col :span="24" class="position__relative">

                    <van-field class="content-approve-address content-approve-address__disabled padding__10px" type="number"
                        v-model="model.userUSDT" :disabled="true"
                        :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }">

                    </van-field>
                </van-col>
            </van-row>

            <van-row class="display-flex__align-center__justify-between">
                <van-col :span="16">
                    {{ $t(`wakuang2.huadianshangxian`) }} 
                    ({{ model.minamount }})
                </van-col>
                <van-col :span="24 - 16">
                    <van-field class="content-skew" type="number" v-model="model.skew" center placeholder="" @input="handleSkew('userUSDT')">
                        <template #button>
                            <!-- <van-icon name="cross" /> -->
                            <span class="font-color__white">%</span>
                        </template>
                    </van-field>
                </van-col>
            </van-row>

            <div class="display-flex__align-center__justify-center width__100per padding-top__10px">
                <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                    @click="abiFunVCDToUSDT">
                    <div class="font-color__theme-glod">
                        {{ $t(`wakuang2.jiaoyi`) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
.content-approve-address {
    width: 100%;
    height: 80px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;

    font-family: "Number", "Text";

    ::v-deep .van-field__control {
        color: #fff;
        text-align: right;
        font-size: 30px;
    }


    &.content-approve-address__disabled {
        height: 50px;
    }
}
::v-deep .content-skew.van-cell {
    background-color: transparent;
    padding-right: 0px;
}
.content-skew {
    ::v-deep .van-field__control {
        color: #fff;
        text-align: right;
        font-size: 14px;
    }
    ::v-deep .van-field__button {
        padding-left: 4px;
    }
}

.data-config {
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 10px;
    right: 5px;

    .cell {
        padding: 2px 6px;
        margin-right: 5px;
        color: #6abaea;
        background-color: #183851;
        border-radius: 12px;
        font-size: 10px;
        transition: all .3s;

        &:active {
            color: #183851;
            background-color: #6abaea;
        }
    }
}

.cut-image {
    img {
        display: block;
        transition: all .3s;
        &:active {
            opacity: .7;
        }
    }
    .img-sub {
        transform: rotate(180deg);
        margin-left: 8px;
    }
}

</style>


<script>
import ApproveAddressBg from "@/assets/img/Private/approve-address.png"
import jiantou_icon from "@/assets/img/Mint/jiantou_icon.png"
import { approveToken, approveUSDT, allowanceTOKEN, allowanceUSDT, abi } from './js/contractMethods'
export default {
    props: {
        pageType: {
            type: String
        }
    },
    data() {
        return {
            imgList: {
                ApproveAddressBg,
                jiantou_icon
            },
            tokenAddress: '',
            configList: [25, 50, 75, 100],
            pageConfig: {
                VCDyue: {
                    label: 'VCD余额',
                    abiName: 'getUserTokenBalance',
                    value: 0,
                    params: ['']
                },
                USDTyue: {
                    label: 'USDT余额',
                    abiName: 'getUserUBalance',
                    value: 0
                }
            },

            pageBtnConfig: {
                VCDToUSDT: {
                    label: '兑换',
                    abiName: 'swapToken',
                },
                USDTToVCD: {
                    label: '兑换',
                    abiName: '_swapU',
                }
            },

            model: {
                userUSDT: '',
                userVCD: '',
                skew: 3,

                minamount: 0,
                realUSDT: 0,
                realVCD: 0
            }
        }
    },
    computed: {
        wallet() {
            // return '0x3E03fF972E681272b703468A443842A5D5aB4Daf'
            return this.$store.state.wallet;
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        async loadData() {

            this.model = {
                userUSDT: '',
                userVCD: '',
                skew: 3,

                minamount: 0,
                realUSDT: 0,
                realVCD: 0
            }


            this.tokenAddress = await this.GetTokenAddress()

            this.pageConfig['VCDyue'].params[0] = this.tokenAddress


            this.abi('VCDyue', this.pageConfig['VCDyue'])
            this.abi('USDTyue', this.pageConfig['USDTyue'])
        },

        Cut() {
            this.model = {
                userUSDT: '',
                userVCD: '',
                skew: 3,

                minamount: 0,
                realUSDT: 0,
                realVCD: 0
            }
            this.$emit("cut")
        },


        // 计算百分比
        ComputedNumber(value) {
            // // console.log('this.pageType', this.pageType);
            if(this.pageType == 1) {
                const data = this.$global.filterRoundDown(this.pageConfig['USDTyue'].value * value / 100, 4)
                this.model.userUSDT = data
                this.handleUSDT()
            }else {
                const data = this.$global.filterRoundDown(this.pageConfig['VCDyue'].value * value / 100, 4)
                this.model.userVCD = data
                this.handleVCD()
            }
        },

        // 自动获取etf
        handleUSDT() {

            setTimeout(async () => {
                const number = await this.USDTToVCD()
                this.model.userVCD = number == 0 ? 0 : (number / (10 ** 18)).toFixed(4);
                this.handleSkew('userVCD')
            }, 100)
        },

        // 自动获取usdt
        handleVCD() {
            setTimeout(async () => {
                const number = await this.VCDToUSDT()
                this.model.userUSDT = number == 0 ? 0 : (number / (10 ** 18)).toFixed(4);
                this.handleSkew('userUSDT')
            }, 100)
        },

        // 滑点配置
        handleSkew(key) {
            // // console.log(this.model.skew)
            if(this.model.skew > 40) {
                this.model.skew = 40
            }else if(this.model.skew < 0) {
                this.model.skew = 0
            }
            if (!!!this.model.skew) return
            const skew = this.model.skew || 0
            const value = this.model[key]
            // // console.log(skew)
            // // console.log(value)
            this.model.minamount = (value * ((100 - skew) * 0.01)).toFixed(4);
        },

        // 根据u获取etf getTokenPrice
        async USDTToVCD() {

            const bigValue = (this.$ethers.BigNumber.from(this.model.userUSDT * 10000 || 0)).mul('100000000000000')
            return new Promise(resolve => {
                abi(
                    {
                        abiName: 'getTokenPrice',
                        params: [bigValue],
                        type: "check",
                    },
                    ({ code, data }) => {
                        if (code == 200) {

                            resolve(data)
                        }
                    }
                );
            })
        },

        // 根据etf获取u getTokenPrice
        async VCDToUSDT() {

            const bigValue = (this.$ethers.BigNumber.from(this.model.userVCD * 10000 || 0)).mul('100000000000000')
            return new Promise(resolve => {
                abi(
                    {
                        abiName: 'getTokenByUPrice',
                        params: [bigValue],
                        type: "check",
                    },
                    ({ code, data }) => {
                        if (code == 200) {

                            resolve(data)
                        }
                    }
                );
            })
        },


        // 获取授权Token地址
        GetTokenAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "_token",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // // console.log('授权token地址:', data)
                            resolve(data)
                        }
                    }
                );
            })

        },




        abi(key, record, notUseWallet) {

            let params = record.params && record.params.length > 0 ? [this.wallet, ...record.params] : [this.wallet]


            abi(
                {
                    abiName: record.abiName,
                    params: params,
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {

                        let value = 0
                        if (this.pageConfig[key].valueKey) {
                            value = data[this.pageConfig[key].valueKey]
                        } else {
                            value = data
                        }
                        if (this.pageConfig[key].isNotNeed18) {
                            this.pageConfig[key].value = value == 0 ? 0 : (value);
                        } else {
                            this.pageConfig[key].value = value == 0 ? 0 : (value / (10 ** 18)).toFixed(4);
                        }

                        // // console.log(record.label, "-", record.abiName, ":", this.pageConfig[key].value)
                        this.$forceUpdate()
                    }
                }
            );
        },


        async abiFunUSDTToVCD() {
            if (!this.wallet) {
                this.showSuccessMessage(this.$i18n.t('tishi.us999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            } else {



                let status = await this.approveUSDT(this.model.userUSDT)
                if (!status) return



                this.$store.commit("SetLoadingText", this.$i18n.t('yilou.us88888'));
                this.$store.commit("SetLoading", true);

                // // console.log('userUSDT', this.model.userUSDT);
                const amount = this.model.userUSDT ** this.model.userUSDT > 0 ? (this.$ethers.BigNumber.from(this.model.userUSDT * 10000)).mul('100000000000000') : 0
                // // console.log('amount', amount);

                const minamount = this.model.minamount && this.model.minamount > 0 ? (this.$ethers.BigNumber.from((this.model.minamount * 10000))).mul('100000000000000') : 0

                const params = [
                    amount,
                    minamount,
                    this.wallet
                ]
                // // console.log('params', params);
                abi(
                    {
                        abiName: 'swapToken',
                        params
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.showSuccessMessage(this.$i18n.t('tishi.us9999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.loadData()
                            this.$emit("close")
                            this.$store.commit("SetLoading", false);
                        } else {
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },


        async abiFunVCDToUSDT() {
            if (!this.wallet) {
                this.showSuccessMessage(this.$i18n.t('tishi.us999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            } else {



                let status = await this.approveToken(this.model.userVCD)
                if (!status) return



                this.$store.commit("SetLoadingText", this.$i18n.t('yilou.us88888'));
                this.$store.commit("SetLoading", true);

                // // console.log('userUSDT', this.model.userVCD);
                console.log('this.model.userVCD', this.model.userVCD);
                const amount = this.model.userVCD && this.model.userVCD > 0 ? (this.$ethers.BigNumber.from(this.model.userVCD * 10000)).mul('100000000000000') : 0
                console.log('amount', amount);

                const minamount = this.model.minamount && this.model.minamount > 0 ? (this.$ethers.BigNumber.from((this.model.minamount * 10000))).mul('100000000000000') : 0

                console.log('minamount', minamount);

                const params = [
                    amount,
                    minamount,
                    this.wallet
                ]
                // // console.log('params', params);
                abi(
                    {
                        abiName: '_swapU',
                        params
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.showSuccessMessage(this.$i18n.t('tishi.us9999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.loadData()
                            this.$emit("close")
                            this.$store.commit("SetLoading", false);
                        } else {
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },




        /* s授权功能 */

        // 获取授权token

        // 获取授权Token地址
        GetTokenAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "_token",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // // console.log('授权token地址:', data)
                            resolve(data)
                        }
                    }
                );
            })

        },



        allowanceTOKEN() {

            return new Promise(async resolve => {

                const TOKENAddress = await this.GetTokenAddress()
                const allowanceTOKENNumber = await allowanceTOKEN(TOKENAddress)

                resolve(this.$global.filterAccuracy18(allowanceTOKENNumber, 0) || 0)
            })
        },


        async approveToken(token) {



            return new Promise(async (resolve) => {


                const allowanceTOKENNumber = await this.allowanceTOKEN()
                // // console.log('已经授权', Number(allowanceTOKENNumber));
                // // console.log('此次需要授权', token);

                if (Number(allowanceTOKENNumber) >= Number(token)) {
                    resolve(true)
                } else {
                    this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us22222'));
                    this.$store.commit("SetLoading", true);

                    const TokenAddress = await this.GetTokenAddress()
                    approveToken({
                        number: token * 10000,
                        address: TokenAddress
                    }, (data) => {
                        if (data) {
                            this.approveState = true
                            resolve(true)
                        } else {
                            this.approveState = false
                            this.$store.commit("SetLoading", false);
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                            resolve(false)
                        }
                    })
                }


            })

        },



        // 获取授权USDT地址
        GetUSDTAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "_USDT",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // // console.log('USDT地址:', data)
                            resolve(data)
                        }
                    }
                );
            })

        },




        allowanceUSDT() {

            return new Promise(async resolve => {

                const USDTdAdress = await this.GetUSDTAddress()
                const allowanceUSDTNumber = await allowanceUSDT(USDTdAdress)

                resolve(this.$global.filterAccuracy18(allowanceUSDTNumber, 0) || 0)
            })
        },

        approveUSDT(usdt) {


            return new Promise(async (resolve) => {

                const allowanceUSDTNumber = await this.allowanceUSDT()
                // // console.log('已经授权', Number(allowanceUSDTNumber));
                // // console.log('此次需要授权', usdt);
                if (Number(allowanceUSDTNumber) >= Number(usdt)) {
                    resolve(true)
                } else {
                    this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us22222'));
                    this.$store.commit("SetLoading", true);

                    const USDTdAdress = await this.GetUSDTAddress()
                    approveUSDT({
                        number: usdt,
                        address: USDTdAdress
                    }, (data) => {
                        if (data) {
                            this.approveState = true
                            resolve(true)
                        } else {
                            this.approveState = false
                            this.$store.commit("SetLoading", false);
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                            resolve(false)
                        }
                    })
                }

            })

        },



    }
}
</script>