module.exports = {

    wakuang1: {
        wodezhanghao: 'Saját fiókom',
        tuijianren: 'Referens',
        fengxiang: 'Megosztás',
        dengjijindu:'Szintfejlődés',
        dangqiandengji:'Jelenlegi szint',
        haixu:'Még szükséges',
        shengji:'Frissítés',
        wodedaibi: 'Saját tokenom',
        wodeVCD: 'Saját VCD',
        zongji:'Összesen',
        jiazhi:'Érték',
        wodekuangchi: 'Bányászati medence',
        wodesuanli: 'Számítási teljesítmény',
        yilingqujiangli: 'Már igénybe vett (U)',
        wodeshouyi: 'Saját bevételmek',
        jiasushouyi: 'Gyorsított bevételmek',
        zongji: 'Összesen (VCD)',
        jiazhi:'Érték (U)',
        zongkuangchi:'Összes bányászati medence',
        chongzhinengliang: 'Energiafeltöltés',
        wodetuandui: 'Saját csapatom',
        wodezhitui: 'Közvetlen promóció',
        zhishuxiashu: 'Alárendeltek',
        dengji:'Szint',
        renshu:'Teljesítmény',
        jine: 'Mennyiség',
        jianglimingxi: 'Jutalmi részletek',
        VCDlingqu: 'VCD igénylése',
        shuliang: 'Mennyiség',
        shijian: 'Idő',
        dangqianjiage: 'Jelenlegi ár',
    },
    wakuang2: {
        duihuan: 'Csere',
        jishijiaoyidaibi: 'Istantáneus kereskedési token',
        yue: 'Egyenleg',
        huadianshangxian: 'Slippage limit',
        jiaoyi: 'Tranzakció',
    },
    guanwang1: {
        us1: "A világ vezető decentralizált pénzügyi platformja",
        us2: 'Szerződés',
        us3: 'Csatlakozzon az affiliate társasághoz',
        us4: 'Célunk',
        us5: 'Válassza az Exchange Traded Funds-t a pénzügyi leverageként kevesebb kezdeti tőkével',
        us6:'·VCD bányászás',
        us7:'2024. augusztus',
        us8: '·IM kommunikáció',
        us9: '2024. november',
        us10: '·VCD publikus lánc teszt hálózat',
        us11: '2025. május',
        us12: '·VCD főhálózat',
        us13: '2025. augusztus',
        us14:'·VCD ökoszisztéma',
        us15:'2025. december',
        us16: 'VCD token gazdaságtan',
        us17: 'Token elosztás',
        us18: 'Teljes kínálat',
        us19: 'Bányászás',
        us20:'Likviditás',
        us21:'Teljesítmény',
        us22: 'Miért fejlesztjük az VCD publikus láncot',
        us23: 'Ahhoz, hogy az VCD a láncon végrehajtható legyen, ami nyitottabb és igazságosabb lesz',
        us24: 'Lehet az VCD más publikus láncokon végrehajtani',
        us25: 'Megvalósítható. Viszont az aktuális publikus láncok mechanizmusi jellemzői miatt csúcsok árulják',
        us26: 'A különbség az VCD publikus lánc és az EVM publikus lánc között',
        us27: 'Módosította a GAS mechanizmust, hogy biztosítsa, hogy a csúcsok nem tudnak előre sorba állni, így a pénzügyek igazságosabbak és biztonságosabbak lesznek',
        us28: 'Az oka, hogy az VCD még nem lett végrehajtva a publikus láncon',
        us29: 'Az EVM aktuális sorbaállási mechanizmusa lehetővé teszi a csúcsoknak, hogy az belső hálózaton sorba álljanak, és ez 100%-os arbitrázzal járhat',
        us30: 'Kapcsolatfelvétel velünk',
    },
    guanwang2: {
        us111: 'Főoldal',
        us222: 'Fehér könyv',
        us333: 'Alapítvány',
        us444: 'Promóció',
        us555: 'Bányászás',
        us666: 'Kapcsolatfelvétel velünk',
        us777: 'Másolás sikeres',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recruitment of super node partners',
    wodedizhi: 'Saját címem',
    wodezhanghao: 'Saját fiókom',
    shouquandizhi: 'Engedélyezési cím',
    shifoushichaojijiedian: 'Lehet-e super csúcs',
    shichaojijiedian:'Igen, super csúcs',
    bushichaojijiedian:'Nem super csúcs',
    dianjijiaru: 'Kattintson a csatlakozáshoz',
},
    xuanfa: {
    baocuntupian:'Kép mentése',
    baocunchenggong:'Mentés sikeres',
},
    tishi: {
    us888:'Beállítás sikeres',
    us999:'Kérjük, csatlakoztassa a pénztárcáját',
    us1111:'Másolás sikeres',
    us2222:'Művelet visszavonása',
    us3333:'Nem tudja magával kötni!',
    us4444:'Kérjük, először csatlakoztassa a pénztárcáját',
    us5555:'Kérjük, adjon meg helyes címet',
    us6666:'Sikeres csatlakozás!',
    us7777:'A jelenlegi cím már egy super csúcs!',
    us8888:'Kérjük, adjon meg érvényes címet',
    us9999:'Csere sikeres',
    us11111:'Kötés...',
    us22222:'Engedélyezés...',
    us33333:'Fizetés...',
    },
    yilou: {
        us44444: 'Az én bevételeim (fagyasztva)',
        us55555: 'Frissítés folyamatban...',
        us66666: 'Felvétel folyamatban...',
        us77777: 'Csatlakozás folyamatban...',
        us88888: 'Csere folyamatban...',
        us99999: 'Megerősítés',
        us111111: 'Mégsem',
    }
}