<style lang="scss" scoped>
.page-header {
    width: 100%;
    height: 45px;
    color: #fff;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
    transition: all .3s;
    font-size: 14px;
    overflow: hidden;
    // background-image: linear-gradient(to bottom, #03162a, rgba(0, 0, 0, 0));
    // background-color: #fff;
    // border-radius: 0px 0px 4px 4px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);
    padding: 0 10px 0 10px;

    // height: 72px;
    // background-color: #1A2660;
    background-color: #0C131D;
    &.active {
        height: 72px;
    }

    .page-header_box {
        // max-width: 1200px;
        // margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-header_logo {
        // width: 60px;
        // height: 100%;
        height: 50px;
        position: relative;
        top: 6px;

        img {
            // width: 100%;
            height: 100%;
            // margin-left: -10px;
        }
    }

    .page-header_menu {
        height: 100%;

        // margin-left: 150px;
        &,
        & * {
            transition: all .3s;
        }

        .page-header_menu-box {
            display: flex;
            height: 100%;

            // opacity: 1;

            align-items: center;
            justify-content: center;

            .page-header_menu-cell {
                // width: 100px;
                height: 60%;
                border-radius: 6px;
                overflow: hidden;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 5px;
                    cursor: pointer;
                    transition: all .3s;

                    &:hover,
                    &.active {
                        background-color: rgba(60, 186, 169, 1);
                    }

                    &:active {
                        color: #8154fe;
                    }
                }
            }

            .page-header_menu-language-icon {
                img {
                    width: 22px;
                    height: 22px;
                    margin: 4px 4px 0 0;
                }
            }

            .page-header_menu-list-icon {
                padding-left: 8px;

                img {
                    width: 22px;
                    height: 22px;
                    margin: 4px 4px 0 0;
                }
            }


            .page-header_menu-language {
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 20px;
                margin: 0px 5px;
                font-size: 10px;

                &:hover {
                    img {
                        border-color: #fff;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    border: 1px solid transparent;
                    border-radius: 50%;
                    transition: all .3s;
                }
            }
        }

    }
}

</style>
<template>
    <div class="page-header" :class="{ active: !navOpen }" :style="$global.moduleShowAnimation(3)">
        <div class="page-header_box">
            <div class="page-header_logo font-color__theme display-flex__align-center font-weight__700 font-size__16px" @click="onSelect(actions[0])">
                <!-- <img :src="logoImage" alt=""> -->
                <img src="../assets/img/logo.png" alt="">
                <!-- <span class="padding__5px font-color__theme-glod">VCD</span> -->
            </div>

            <div class="page-header_menu">

                <div class="page-header_menu-box">

                    <ContentWallet style="margin-right: 20px;"></ContentWallet>

                    <div class="page-header_menu-language-icon margin-right__10px" @click="cutLanguage">
                        <img :src="languageImage" alt="">
                    </div>




                    <van-popover popover-class="c-class" v-model="showPopover" theme="dark" placement="bottom-end" trigger="click"
                        @select="onSelect" :actions="actions">
                        <template #reference>
                            <div class="page-header_menu-list-icon">
                                <img :src="ListIcon" alt="">
                            </div>
                        </template>
                        <template #default>
                            <!-- <div v-for="(item, index) of actions" :key="index" role="menuitem" class="van-popover__action" @click="onSelect(item)">
                                <div class="van-popover__action-text van-hairline--bottom">
                                    {{ $t(`${item.textKey}`) }}
                                </div>
                            </div> -->

                            <div class="d-content">
                                <div class="close-icon">
                                    <img @click="close" src="../assets/img/r2/close.png" alt="" srcset="">
                                </div>
                                <div class="d-wrapper">

                                    <div class="w-li" v-for="(item, index) of actions" :key="index" @click="onSelect(item)">
                                        <div class="icon-m">
                                            <img class="d-icon" :src="item.icon" alt="" srcset="">
                                        </div>
                                        <div class="mm-main">
                                            <div class="d-text">{{ $t(`${item.textKey}`) }}</div>
                                            <img class="li-img" src="../assets/img/r2/line.png" alt="" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </van-popover>



                </div>
            </div>
        </div>



        <!-- <div style="position: absolute;z-index: -1000;opacity: 0;visibility: hidden;">
            <audio controls autoplay>
            <source src="../assets/static/go-time.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div> -->
    </div>
</template>


<script>
import logoImage from '@/assets/img/redesign/logo.png'
import languageImage from '@/assets/img/redesign/lang.png'
import ListIcon from '@/assets/img/redesign/more.png'
import ContentWallet from "@/components/ContentWallet.vue";

import Icon1 from '@/assets/img/r2/home.png'
import Icon2 from '@/assets/img/r2/bps.png'
import Icon3 from '@/assets/img/r2/jjh.png'

import Icon4 from '@/assets/img/r2/xuanfa.png'
import Icon5 from '@/assets/img/r2/wk.png'
import Icon6 from '@/assets/img/r2/swap.png'

import Icon7 from '@/assets/img/r2/lxwm.png'

export default {
    name: 'page-header',
    props: {
        columns: {
            type: Array,
            default: []
        }
    },
    components: {
        ContentWallet
    },
    computed: {
        active() {
            return this.$route.fullPath
        },
        language() {
            const language = this.$store.state.language || '简体中文'
            const item = this.columns.find(record => record.value == language) || {}
            return item.text || '简体中文'
        },
        navOpen() {
            // if(this.active != '/') {
            //     return true
            // }else
            
            if (this.scrolleTop == 100) {
                return true
            } else {
                return false
            }
        }
    },
    data() {
        return {
            logoImage,
            languageImage,
            ListIcon,
 
            scrolleTop: 0,
            showPopover: false,
            actions: [
                { textKey: 'guanwang2.us111', text: '', url: '/', icon: Icon5 },
                { textKey: 'Swap', text: 'Swap', url: '/Swap', icon: Icon6, href: 'https://pancakeswap.finance/?inputCurrency=0x4EE28A2eE5E52134420A5A1741e9d3FF1e6997F0&outputCurrency=0x55d398326f99059fF775485246999027B3197955' },
                { textKey: 'guanwang2.us999', text: '', url: '', icon: Icon2 },
            ],
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        cutLanguage(lang) {
            this.$emit("show")
        },
        handleScroll() {
            //方法一
            var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)

            if (top >= 100) {
                this.scrolleTop = 100
            } else {
                this.scrolleTop = top
            }
        },
        onSelect(e) {
            // console.log(e)
            if (e.isCutLanguage) {
                this.cutLanguage()
            } else if (e.isCallUs) {
                this.$emit("callUs")
            } else if(e.href) {
                window.location.href = e.href
            } else {
                if(e.url) {
                    this.$router.push(e.url)
                }else {
                    this.showSuccessMessage("敬请期待!");
                }
            }
            this.showPopover = false
        },
        close() {
            this.showPopover = false
        }
    }

}
</script>


<style lang="scss">
.van-popover--dark {
    .van-popover__content {
        // background-color: #000 !important;
        background-color: transparent !important;
    }
}
.van-picker, .van-popup, .van-picker__columns {
    background-color: transparent !important;
}

.d-content {
    position: relative;
    width: 180px;
    height: 300px;
    background-image: url('../assets/img/r2/ce.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 42px;
    box-sizing: border-box;
    .close-icon {
        position: absolute;
        right: 20px;
        top: 13px;
        width: 21px;
        height: 21px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .d-wrapper {
        padding-top: 50px;
        box-sizing: border-box;
        .w-li {
            display: flex;
            align-items: center;
            height: 50px;
            color: #51bdee;
            .icon-m {
                // margin-right: 10px;
                position: relative;
                top: -5px;
            }
           
            .li-img {
                width: 100px;
                height: 2px;
            }
            .d-icon {
                width: 20px;
                height: 20px;
            }
            .mm-main {
                width: 80px;
                text-align: center;
                .d-text {
                    flex-grow: 1;
                }
            }
        }
    }
}
</style>