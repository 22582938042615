module.exports = {

    wakuang1: {
        wodezhanghao: '私のアカウント',
        tuijianren: '参照者',
        fengxiang: 'シェア',
        dengjijindu:'レベル進行',
        dangqiandengji:'現在のレベル',
        haixu:'まだ必要',
        shengji:'アップグレード',
        wodedaibi: '私のトークン',
        wodeVCD: '私のVCD',
        zongji:'合計',
        jiazhi:'価値',
        wodekuangchi: '採掘プール',
        wodesuanli: '計算能力',
        yilingqujiangli: '既に請求済み（U）',
        wodeshouyi: '私の収益',
        jiasushouyi: '加速収益',
        zongji: '合計（VCD）',
        jiazhi:'価値（U）',
        zongkuangchi:'合計採掘プール',
        chongzhinengliang: 'エネルギー再充電',
        wodetuandui: '私のチーム',
        wodezhitui: '直接プロモーション',
        zhishuxiashu: '部下',
        dengji:'レベル',
        renshu:'パフォーマンス',
        jine: '金額',
        jianglimingxi: '報酬詳細',
        VCDlingqu: 'VCD請求',
        shuliang: '数量',
        shijian: '時間',
        dangqianjiage: '現在価格',
    },
    wakuang2: {
        duihuan: '交換',
        jishijiaoyidaibi: 'インスタント取引トークン',
        yue: '残高',
        huadianshangxian: 'スリッページ制限',
        jiaoyi: '取引',
    },
    guanwang1: {
        us1: '世界有数の分散型金融プラットフォーム',
        us2: '契約',
        us3: 'アフィリエイト会社に参加する',
        us4: '私たちの目標',
        us5: '初期資本が少ない金融レバレッジとして取引所取引ファンドを選択する',
        us6:'·VCD採掘',
        us7:'2024年8月',
        us8: '·IM通信',
        us9: '2024年11月',
        us10: '·VCDパブリックチェーンテストネット',
        us11: '2025年5月',
        us12: '·VCDメインネット',
        us13: '2025年8月',
        us14:'·VCDエコシステム',
        us15:'2025年12月',
        us16: 'VCDトークン経済学',
        us17: 'トークン配布',
        us18: '総供給量',
        us19: '採掘',
        us20:'流動性',
        us21:'パフォーマンス',
        us22: 'なぜVCDパブリックチェーンを開発するのか',
        us23: 'VCDがチェーン上で実行できるようにするため、これはよりオープンで公平になる',
        us24: 'VCDは他のパブリックチェーンで実行できるのか',
        us25: '実現できる。ただし、現在のパブリックチェーンのメカニズムの特性により、ノードによって裁定取引される',
        us26: 'VCDパブリックチェーンとEVMパブリックチェーンの違い',
        us27: 'GASメカニズムを修正して、ノードが事前にキューに入れないようにし、金融をより公平で安全にする',
        us28: 'VCDがパブリックチェーンで実行されていない理由',
        us29: '現在のEVMのキューイングメカニズムはノードが内部ネットワークでキューに入れることを許可し、100％裁定取引できる',
        us30: 'お問い合わせ',
    },
    guanwang2: {
        us111: 'ホームページ',
        us222: 'ホワイトペーパー',
        us333: '財団',
        us444: 'プロモーション',
        us555: '採掘',
        us666: 'お問い合わせ',
        us777: 'コピー成功',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'スーパーノードパートナーの募集',
    wodedizhi: '私の住所',
    wodezhanghao: '私のアカウント',
    shouquandizhi: '承認住所',
    shifoushichaojijiedian: 'スーパーノードかどうか',
    shichaojijiedian:'スーパーノードです',
    bushichaojijiedian:'スーパーノードではありません',
    dianjijiaru: 'クリックして参加',
},
    xuanfa: {
    baocuntupian:'画像保存',
    baocunchenggong:'保存成功',
},
    tishi: {
    us888:'設定成功',
    us999:'ウォレットを接続してください',
    us1111:'コピー成功',
    us2222:'操作キャンセル',
    us3333:'自分と結びつけることはできません！',
    us4444:'まずウォレットを接続してください',
    us5555:'正しい住所を入力してください',
    us6666:'参加成功！',
    us7777:'現在の住所はすでにスーパーノードです！',
    us8888:'有効な住所を入力してください',
    us9999:'交換成功',
    us11111:'結びつけ中...',
    us22222:'承認中...',
    us33333:'支払い中...',
    },
    yilou: {
        us44444: '私の収益（凍結）',
        us55555: 'アップグレード中...',
        us66666: '受け取り中...',
        us77777: '参加中...',
        us88888: '交換中...',
        us99999: '確認',
        us111111: 'キャンセル'
    }
}