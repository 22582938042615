module.exports = {

    wakuang1: {
        wodezhanghao: 'Account yangu',
        tuijianren: 'Referrer',
        fengxiang: 'Share',
        dengjijindu:'Kufambira mberi kwemazinga',
        dangqiandengji:'Chiyero chazvino',
        haixu:'Zvichiri kudiwa',
        shengji:'Upgrade',
        wodedaibi: 'Chiratidzo changu',
        wodeVCD: 'VCD yangu',
        zongji:'Total',
        jiazhi:'Value',
        wodekuangchi: 'Mining pool',
        wodesuanli: 'Computing power',
        yilingqujiangli: 'Zvagara zvichinzi (U)',
        wodeshouyi: 'Mari yangu',
        jiasushouyi: 'Yakawedzerwa mihoro',
        zongji: 'Zvose (VCD)',
        jiazhi:'Kukosha (U)',
        zongkuangchi:'Total mining pool',
        chongzhinengliang: 'Recharge simba',
        wodetuandui: 'Chikwata changu',
        wodezhitui: 'Kusimudzira kwakananga',
        zhishuxiashu: 'Subordinates',
        dengji:'Level',
        renshu:'Performance',
        jine: 'Mari',
        jianglimingxi: 'Tsanangudzo yemubairo',
        VCDlingqu: 'VCD kudai',
        shuliang: 'Quantity',
        shijian: 'Nguva',
        dangqianjiage: 'Mutengo wazvino',
    },
    wakuang2: {
        duihuan: 'Exchange',
        jishijiaoyidaibi: 'Instant yekutengesa chiratidzo',
        yue: 'Balance',
        huadianshangxian: 'Slippage limit',
        jiaoyi: 'Transaction',
    },
    guanwang1: {
        us1: 'Iyo inotungamira pasi rose ine decentralized zvemari chikuva',
        us2: 'Contract',
        us3: 'Joinha kambani yakabatana',
        us4: 'Chinangwa chedu',
        us5: 'Sarudza mari yekuchinjana-inotengeswa semari yako yekuwedzera ine mari shoma yekutanga',
        us6:'·VCD migodhi',
        us7:'Nyamavhuvhu 2024',
        us8: '·IM kutaurirana',
        us9: 'Mbudzi 2024',
        us10: '·VCD public chain testnet',
        us11: 'Chivabvu 2025',
        us12: '·VCD mainnet',
        us13: 'Nyamavhuvhu 2025',
        us14:'·VCD ecosystem',
        us15:'Zvita 2025',
        us16: 'VCD token economics',
        us17: 'Token distribution',
        us18: 'Total supply',
        us19: 'Mining',
        us20:'Liquidity',
        us21:'Performance',
        us22: 'Sei uchigadzira VCD yeruzhinji cheni',
        us23: 'Kuita kuti VCD iitwe pacheni, iyo ichave yakavhurika uye yakanaka',
        us24: 'Inogona VCD kuurayiwa pane mamwe maketani eruzhinji',
        us25: 'Zvinogona kuitika. Nekudaro, nekuda kwehunhu hwemacheni emaketani eruzhinji azvino, ichave yakapokana nemanodhi',
        us26: 'Musiyano uripo pakati peVCD yeruzhinji ketani uye EVM yeruzhinji cheni',
        us27: 'Yakagadziridza nzira yeGAS kuti ive nechokwadi chekuti node hadzigone kumira mumutsara, zvichiita kuti mari ive yakanaka uye yakachengeteka',
        us28: 'Chikonzero nei VCD isina kuurayiwa pamaketani eruzhinji',
        us29: 'Ikozvino queuing mechanism yeEVM inobvumira node kuti dzimire mukati metiweki yemukati, iyo inogona kupikisa 100%',
        us30: 'Taura nesu',
    },
    guanwang2: {
        us111: 'Homepage',
        us222: 'Whitepaper',
        us333: 'Foundation',
        us444: 'Promotion',
        us555: 'Mining',
        us666: 'Taura nesu',
        us777: 'Kukopa kwabudirira',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recruitment of super node partners',
    wodedizhi: 'My address',
    wodezhanghao: 'My account',
    shouquandizhi: 'Authorization address',
    shifoushichaojijiedian: 'Whether it is a super node',
    shichaojijiedian:'Is a super node',
    bushichaojijiedian:'Not a super node',
    dianjijiaru: 'Click to join',
},
    xuanfa: {
    baocuntupian:'Sevha mufananidzo',
    baocunchenggong:'Kuchengeta kwakabudirira',
},
    tishi: {
    us888:'Kuseta kwakabudirira',
    us999:'Ndapota batanidza chikwama',
    us1111:'Kukopa kwabudirira',
    us2222:'Kanzura kushanda',
    us3333:'Haukwanise kuzvisunga newe!',
    us4444:'Ndapota batanidza chikwama chekutanga',
    us5555:'Ndapota isa kero chaiyo',
    us6666:'Kujoinwa zvakabudirira!',
    us7777:'Kero yazvino yatove super node!',
    us8888:'Ndokumbira uise kero inoshanda',
    us9999:'Kuchinjana kwakabudirira',
    us11111:'Kusunga...',
    us22222:'Kubvumidza...',
    us33333:'Kubhadhara...',
    },
    yilou: {
        us44444: "Zvinhu zvedu zvinhu zvangu (zvakagadzirwa)",
        us55555: "Mukati mekugadzirisa...",
        us66666: "Mukati mekugamuchira...",
        us77777: "Mukati mekunoenda...",
        us88888: "Mukati mekubvisa...",
        us99999: "Kugadzirisa",
        us111111: "Kubvisa"
    }
}