<template>
    <div class="module-line">
        <div class="line line-left">
        </div>
        <div class="line">
            <div class="content">
                <img :src="fishIcon" alt="">
            </div>
        </div>
        <div class="line line-right">
        </div>
    </div>
</template>

<script>
import fishIcon from "@/assets/img/logo.png"
export default {
    data() {
        return {
            fishIcon
        }
    }
}
</script>
