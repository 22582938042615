module.exports = {

    wakuang1: {
        wodezhanghao: 'Contul meu',
        tuijianren: 'Referent',
        fengxiang: 'Partajează',
        dengjijindu:'Progresul nivelului',
        dangqiandengji:'Nivelul curent',
        haixu:'Încă este necesar',
        shengji:'Actualizare',
        wodedaibi: 'Tokenul meu',
        wodeVCD: 'VCD-ul meu',
        zongji:'Total',
        jiazhi:'Valoare',
        wodekuangchi: 'Piscina de minare',
        wodesuanli: 'Puterea de calcul',
        yilingqujiangli: 'Deja revendicat (U)',
        wodeshouyi: 'Veniturile mele',
        jiasushouyi: 'Venituri accelerate',
        zongji: 'Total (VCD)',
        jiazhi:'Valoare (U)',
        zongkuangchi:'Totalul piscinei de minare',
        chongzhinengliang: 'Reîncărca energie',
        wodetuandui: 'Echipa mea',
        wodezhitui: 'Promovare directă',
        zhishuxiashu: 'Subordonați',
        dengji:'Nivel',
        renshu:'Performanță',
        jine: 'Cantitate',
        jianglimingxi: 'Detalii despre recompensă',
        VCDlingqu: 'Cererea VCD',
        shuliang: 'Cantitate',
        shijian: 'Timp',
        dangqianjiage: 'Prețul curent',
    },
    wakuang2: {
        duihuan: 'Schimb',
        jishijiaoyidaibi: 'Token de tranzacționare instantanee',
        yue: 'Sold',
        huadianshangxian: 'Limita de alunecare',
        jiaoyi: 'Tranzacție',
    },
    guanwang1: {
        us1: "Platforma financiară descentralizată de vârf din lume",
        us2: 'Contract',
        us3: 'Alăturați-vă companiei afiliate',
        us4: 'Obiectivul nostru',
        us5: 'Alegeți fondurile tranzacționate la bursă ca pârghie financiară cu un capital inițial mai mic',
        us6:'·Minarea VCD',
        us7:'August 2024',
        us8: '·Comunicare IM',
        us9: 'Noiembrie 2024',
        us10: '·Testnet-ul lanțului public VCD',
        us11: 'Mai 2025',
        us12: '·Lanțul principal VCD',
        us13: 'August 2025',
        us14:'·Ecosistemul VCD',
        us15:'Decembrie 2025',
        us16: 'Economia tokenului VCD',
        us17: 'Distribuția tokenului',
        us18: 'Ofertă totală',
        us19: 'Minare',
        us20:'Lichiditate',
        us21:'Performanță',
        us22: 'De ce dezvoltăm lanțul public VCD',
        us23: 'Pentru a permite executarea VCD pe lanț, ceea ce va fi mai deschis și corect',
        us24: 'Poate fi executat VCD pe alte lanțuri publice',
        us25: 'Poate fi realizat. Cu toate acestea, din cauza caracteristicilor mecanismului lanțurilor publice actuale, va fi arbitrat de noduri',
        us26: 'Diferența dintre lanțul public VCD și lanțul public EVM',
        us27: 'Am modificat mecanismul GAS pentru a asigura că nodurile nu pot face coadă în avans, făcând finanțele mai corecte și mai sigure',
        us28: 'Motivul pentru care VCD nu a fost executat pe lanțul public',
        us29: 'Mecanismul actual de coadă al EVM permite nodurilor să facă coadă în rețeaua internă, ceea ce poate efectua arbitraj 100%',
        us30: 'Contactați-ne',
    },
    guanwang2: {
        us111: 'Pagina de pornire',
        us222: 'Whitepaper',
        us333: 'Fundație',
        us444: 'Promovare',
        us555: 'Minare',
        us666: 'Contactați-ne',
        us777: 'Copiere reușită',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recrutarea partenerilor de noduri super',
    wodedizhi: 'Adresa mea',
    wodezhanghao: 'Contul meu',
    shouquandizhi: 'Adresa de autorizare',
    shifoushichaojijiedian: 'Dacă este un nod super',
    shichaojijiedian:'Este un nod super',
    bushichaojijiedian:'Nu este un nod super',
    dianjijiaru: 'Dați clic pentru a vă alătura',
},
    xuanfa: {
    baocuntupian:'Salvați imaginea',
    baocunchenggong:'Salvare reușită',
},
    tishi: {
    us888:'Setare reușită',
    us999:'Vă rugăm să conectați portofelul',
    us1111:'Copiere reușită',
    us2222:'Anulare operațiune',
    us3333:'Nu vă puteți lega de dvs. însuși!',
    us4444:'Vă rugăm să conectați mai întâi portofelul',
    us5555:'Vă rugăm să introduceți adresa corectă',
    us6666:'Înscriere reușită!',
    us7777:'Adresa curentă este deja un nod super!',
    us8888:'Vă rugăm să introduceți o adresă validă',
    us9999:'Schimb reușit',
    us11111:'Legare...',
    us22222:'Autorizare...',
    us33333:'Plată...',
    },
    yilou: {
        us44444: 'Veniturile mele (înghețate)',
        us55555: 'În curs de actualizare...',
        us66666: 'În curs de primire...',
        us77777: 'În curs de aderare...',
        us88888: 'În curs de schimb...',
        us99999: 'Confirmare',
        us111111: 'Anulare',
    }
}