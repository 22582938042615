module.exports = {

    wakuang1: {
        wodezhanghao: 'Minha conta',
        tuijianren: 'Referenciador',
        fengxiang: 'Compartilhar',
        dengjijindu:'Progresso do nível',
        dangqiandengji:'Nível atual',
        haixu:'Ainda necessário',
        shengji:'Atualizar',
        wodedaibi: 'Meu token',
        wodeVCD: 'Meu VCD',
        zongji:'Total',
        jiazhi:'Valor',
        wodekuangchi: 'Piscina de mineração',
        wodesuanli: 'Poder de computação',
        yilingqujiangli: 'Já reivindicado (U)',
        wodeshouyi: 'Meus ganhos',
        jiasushouyi: 'Ganhos acelerados',
        zongji: 'Total (VCD)',
        jiazhi:'Valor (U)',
        zongkuangchi:'Total da piscina de mineração',
        chongzhinengliang: 'Recarregar energia',
        wodetuandui: 'Meu time',
        wodezhitui: 'Promoção direta',
        zhishuxiashu: 'Subordinados',
        dengji:'Nível',
        renshu:'Desempenho',
        jine: 'Quantia',
        jianglimingxi: 'Detalhes da recompensa',
        VCDlingqu: 'Reivindicação do VCD',
        shuliang: 'Quantidade',
        shijian: 'Tempo',
        dangqianjiage: 'Preço atual',
    },
    wakuang2: {
        duihuan: 'Troca',
        jishijiaoyidaibi: 'Token de negociação instantânea',
        yue: 'Saldo',
        huadianshangxian: 'Limite de deslizamento',
        jiaoyi: 'Transação',
    },
    guanwang1: {
        us1: "A plataforma financeira descentralizada líder mundial",
        us2: 'Contrato',
        us3: 'Junte-se à empresa afiliada',
        us4: 'Nosso objetivo',
        us5: 'Escolha fundos negociados em bolsa como sua alavancagem financeira com menos capital inicial',
        us6:'·Mineração VCD',
        us7:'Agosto de 2024',
        us8: '·Comunicação IM',
        us9: 'Novembro de 2024',
        us10: '·Testnet da cadeia pública VCD',
        us11: 'Maio de 2025',
        us12: '·Mainnet VCD',
        us13: 'Agosto de 2025',
        us14:'·Ecossistema VCD',
        us15:'Dezembro de 2025',
        us16: 'Economia do token VCD',
        us17: 'Distribuição do token',
        us18: 'Fornecimento total',
        us19: 'Mineração',
        us20:'Liquidez',
        us21:'Desempenho',
        us22: 'Por que desenvolver a cadeia pública VCD',
        us23: 'Para permitir que o VCD seja executado na cadeia, o que será mais aberto e justo',
        us24: 'O VCD pode ser executado em outras cadeias públicas',
        us25: 'Pode ser realizado. No entanto, devido às características do mecanismo das cadeias públicas atuais, será arbitrado por nós',
        us26: 'A diferença entre a cadeia pública VCD e a cadeia pública EVM',
        us27: 'Modificou o mecanismo GAS para garantir que os nós não possam se enfileirar antecipadamente, tornando as finanças mais justas e seguras',
        us28: 'A razão pela qual o VCD não foi executado na cadeia pública',
        us29: 'O mecanismo de enfileiramento atual do EVM permite que os nós se enfileirem na rede interna, o que pode fazer arbitragem 100%',
        us30: 'Contate-nos',
    },
    guanwang2: {
        us111: 'Página inicial',
        us222: 'Whitepaper',
        us333: 'Fundação',
        us444: 'Promoção',
        us555: 'Mineração',
        us666: 'Contate-nos',
        us777: 'Cópia bem-sucedida',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recrutamento de parceiros de nó super',
    wodedizhi: 'Meu endereço',
    wodezhanghao: 'Minha conta',
    shouquandizhi: 'Endereço de autorização',
    shifoushichaojijiedian: 'Se é um nó super',
    shichaojijiedian:'É um nó super',
    bushichaojijiedian:'Não é um nó super',
    dianjijiaru: 'Clique para participar',
},
    xuanfa: {
    baocuntupian:'Salvar imagem',
    baocunchenggong:'Salvamento bem-sucedido',
},
    tishi: {
    us888:'Configuração bem-sucedida',
    us999:'Por favor, conecte a carteira',
    us1111:'Cópia bem-sucedida',
    us2222:'Cancelar operação',
    us3333:'Não pode se vincular a si mesmo!',
    us4444:'Por favor, conecte a carteira primeiro',
    us5555:'Por favor, insira o endereço correto',
    us6666:'Inscrição bem-sucedida!',
    us7777:'O endereço atual já é um nó super!',
    us8888:'Por favor, insira um endereço válido',
    us9999:'Troca bem-sucedida',
    us11111:'Vinculando...',
    us22222:'Autorizando...',
    us33333:'Pagando...',
    },
    yilou: {
        us44444: 'Meus rendimentos (congelados)',
        us55555: 'Em atualização...',
        us66666: 'Em processo de recebimento...',
        us77777: 'Em processo de adesão...',
        us88888: 'Em processo de troca...',
        us99999: 'Confirmar',
        us111111: 'Cancelar'
    }
}