module.exports = {

    wakuang1: {
        wodezhanghao: 'Akun Saya',
        tuijianren: 'Referrer',
        fengxiang: 'Bagikan',
        dengjijindu:'Kemajuan Tingkat',
        dangqiandengji:'Tingkat Saat Ini',
        haixu:'Masih Dibutuhkan',
        shengji:'Naik Tingkat',
        wodedaibi: 'Token Saya',
        wodeVCD: 'VCD Saya',
        zongji:'Total',
        jiazhi:'Nilai',
        wodekuangchi: 'Kolam Pertambangan',
        wodesuanli: 'Daya Komputasi',
        yilingqujiangli: 'Sudah Diklaim (U)',
        wodeshouyi: 'Pendapatan Saya',
        jiasushouyi: 'Pendapatan Dipercepat',
        zongji: 'Total (VCD)',
        jiazhi:'Nilai (U)',
        zongkuangchi:'Total Kolam Pertambangan',
        chongzhinengliang: 'Isi Ulang Energi',
        wodetuandui: 'Tim Saya',
        wodezhitui: 'Promosi Langsung',
        zhishuxiashu: 'Bawahan',
        dengji:'Tingkat',
        renshu:'Kinerja',
        jine: 'Jumlah',
        jianglimingxi: 'Detail Hadiah',
        VCDlingqu: 'Klaim VCD',
        shuliang: 'Jumlah',
        shijian: 'Waktu',
        dangqianjiage: 'Harga Saat Ini',
    },
    wakuang2: {
        duihuan: 'Penukaran',
        jishijiaoyidaibi: 'Token Perdagangan Instan',
        yue: 'Saldo',
        huadianshangxian: 'Batas Slip',
        jiaoyi: 'Transaksi',
    },
    guanwang1: {
        us1: 'Platform keuangan terdesentralisasi terkemuka di dunia',
        us2: 'Kontrak',
        us3: 'Bergabung dengan perusahaan afiliasi',
        us4: 'Tujuan Kami',
        us5: 'Pilih dana yang diperdagangkan di bursa sebagai leverage keuangan Anda dengan modal awal yang lebih sedikit',
        us6:'·Pertambangan VCD',
        us7:'Agustus 2024',
        us8: '·Komunikasi IM',
        us9: 'November 2024',
        us10: '·Jaringan Uji Coba Rantai Publik VCD',
        us11: 'Mei 2025',
        us12: '·Rantai Utama VCD',
        us13: 'Agustus 2025',
        us14:'·Ekosistem VCD',
        us15:'Desember 2025',
        us16: 'Ekonomi Token VCD',
        us17: 'Distribusi Token',
        us18: 'Pasokan Total',
        us19: 'Pertambangan',
        us20:'Likuiditas',
        us21:'Kinerja',
        us22: 'Mengapa mengembangkan rantai publik VCD',
        us23: 'Untuk memungkinkan VCD dieksekusi di rantai, ini akan lebih terbuka dan adil',
        us24: 'Bisakah VCD dieksekusi di rantai publik lain',
        us25: 'Bisa direalisasikan. Namun, karena karakteristik mekanisme rantai publik saat ini, akan dimanfaatkan oleh node',
        us26: 'Perbedaan antara rantai publik VCD dan rantai publik EVM',
        us27: 'Mengubah mekanisme GAS untuk memastikan node tidak dapat mengantri lebih dulu, membuat keuangan lebih adil dan aman',
        us28: 'Alasan mengapa VCD belum dieksekusi di rantai publik',
        us29: 'Mekanisme antrian saat ini dari EVM memungkinkan node untuk mengantri di jaringan internal, yang dapat melakukan arbitrase 100%',
        us30: 'Hubungi Kami',
    },
    guanwang2: {
        us111: 'Halaman Utama',
        us222: 'Buku Putih',
        us333: 'Yayasan',
        us444: 'Promosi',
        us555: 'Pertambangan',
        us666: 'Hubungi Kami',
        us777: 'Berhasil Disalin',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Perekrutan Mitra Simpul Super',
    wodedizhi: 'Alamat Saya',
    wodezhanghao: 'Akun Saya',
    shouquandizhi: 'Alamat Otorisasi',
    shifoushichaojijiedian: 'Apakah Ini Simpul Super',
    shichaojijiedian:'Ini Simpul Super',
    bushichaojijiedian:'Bukan Simpul Super',
    dianjijiaru: 'Klik untuk Bergabung',
},
    xuanfa: {
    baocuntupian:'Simpan Gambar',
    baocunchenggong:'Simpan Berhasil',
},
    tishi: {
    us888:'Pengaturan Berhasil',
    us999:'Silakan Hubungkan Dompet',
    us1111:'Berhasil Disalin',
    us2222:'Batalkan Operasi',
    us3333:'Tidak Bisa Mengikat dengan Diri Sendiri!',
    us4444:'Silakan Hubungkan Dompet Terlebih Dahulu',
    us5555:'Silakan Masukkan Alamat yang Benar',
    us6666:'Bergabung Berhasil!',
    us7777:'Alamat Saat Ini Sudah Jadi Simpul Super!',
    us8888:'Silakan Masukkan Alamat yang Valid',
    us9999:'Penukaran Berhasil',
    us11111:'Mengikat...',
    us22222:'Otorisasi...',
    us33333:'Membayar...',
    },
    yilou: {
        us44444: 'Pendapatan saya (beku)',
        us55555: 'Dalam proses upgrade...',
        us66666: 'Dalam proses penerimaan...',
        us77777: 'Dalam proses bergabung...',
        us88888: 'Dalam proses penukaran...',
        us99999: 'Konfirmasi',
        us111111: 'Batalkan'
    }
}