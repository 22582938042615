module.exports = {

    wakuang1: {
        wodezhanghao: 'Hesabım',
        tuijianren: 'Referans',
        fengxiang: 'Paylaş',
        dengjijindu:'Seviye ilerleme',
        dangqiandengji:'Şu anki seviye',
        haixu:'Hala gerekli',
        shengji:'Güncelleme',
        wodedaibi: 'Benim tokenım',
        wodeVCD: 'Benim VCD',
        zongji:'Toplam',
        jiazhi:'Değer',
        wodekuangchi: 'Madencilik havuzu',
        wodesuanli: 'Hesaplama gücü',
        yilingqujiangli: 'Zaten talep edilmiş (U)',
        wodeshouyi: 'Benim kazancım',
        jiasushouyi: 'Hızlandırılmış kazanç',
        zongji: 'Toplam (VCD)',
        jiazhi:'Değer (U)',
        zongkuangchi:'Toplam madencilik havuzu',
        chongzhinengliang: 'Enerji şarj et',
        wodetuandui: 'Benim takımım',
        wodezhitui: 'Doğrudan tanıtım',
        zhishuxiashu: 'Alt kademeler',
        dengji:'Seviye',
        renshu:'Performans',
        jine: 'Miktar',
        jianglimingxi: 'Ödül detayları',
        VCDlingqu: 'VCD talebi',
        shuliang: 'Miktar',
        shijian: 'Zaman',
        dangqianjiage: 'Şu anki fiyat',
    },
    wakuang2: {
        duihuan: 'Değişim',
        jishijiaoyidaibi: 'Anlık işlem tokenı',
        yue: 'Bakiye',
        huadianshangxian: 'Kayma sınırı',
        jiaoyi: 'İşlem',
    },
    guanwang1: {
        us1: "Dünyanın önde gelen merkezi olmayan finansal platformu",
        us2: 'Sözleşme',
        us3: 'Ortak şirkete katıl',
        us4: 'Amacımız',
        us5: 'Düşük başlangıç sermayesiyle finansal kaldıraç olarak borsa işlem fonlarını seçin',
        us6:'·VCD madenciliği',
        us7:'Ağustos 2024',
        us8: '·IM iletişimi',
        us9: 'Kasım 2024',
        us10: '·VCD kamu zinciri test ağı',
        us11: 'Mayıs 2025',
        us12: '·VCD ana ağ',
        us13: 'Ağustos 2025',
        us14:'·VCD ekosistemi',
        us15:'Aralık 2025',
        us16: 'VCD token ekonomisi',
        us17: 'Token dağıtımı',
        us18: 'Toplam arz',
        us19: 'Madencilik',
        us20:'Likidite',
        us21:'Performans',
        us22: 'VCD kamu zincirini neden geliştiriyoruz',
        us23: 'VCD nin zincirde çalışmasını sağlamak, bu daha açık ve adil olacak',
        us24: 'VCD başka kamu zincirlerinde çalışabilir mi',
        us25: 'Gerçekleştirilebilir. Ancak mevcut kamu zincirlerinin mekanizma özellikleri nedeniyle, düğümler tarafından manipüle edilebilir',
        us26: 'VCD kamu zinciri ile EVM kamu zinciri arasındaki fark',
        us27: 'GAS mekanizmasını değiştirerek düğümlerin önceden sıraya girmesini önledik, finansı daha adil ve güvenli hale getirdik',
        us28: 'VCD nin henüz kamu zincirinde çalışmamış olmasının nedeni',
        us29: 'EVM nin mevcut sıralama mekanizması düğümlerin iç ağda sıraya girmesine izin veriyor, bu da %100 arbitraj yapabilir',
        us30: 'Bize ulaşın',
    },
    guanwang2: {
        us111: 'Ana sayfa',
        us222: 'Beyaz kağıt',
        us333: 'Vakıf',
        us444: 'Tanıtım',
        us555: 'Madencilik',
        us666: 'Bize ulaşın',
        us777: 'Kopyalama başarılı',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Süper düğüm ortakları alımı',
    wodedizhi: 'Adresim',
    wodezhanghao: 'Hesabım',
    shouquandizhi: 'Yetkilendirme adresi',
    shifoushichaojijiedian: 'Süper düğüm olup olmadığı',
    shichaojijiedian:'Süper düğüm',
    bushichaojijiedian:'Süper düğüm değil',
    dianjijiaru: 'Katılmak için tıklayın',
},
    xuanfa: {
    baocuntupian:'Resim kaydet',
    baocunchenggong:'Kaydetme başarılı',
},
    tishi: {
    us888:'Ayar başarılı',
    us999:'Lütfen cüzdanınızı bağlayın',
    us1111:'Kopyalama başarılı',
    us2222:'İşlemi iptal et',
    us3333:'Kendinizle bağlantı kuramazsınız!',
    us4444:'Lütfen önce cüzdanınızı bağlayın',
    us5555:'Lütfen doğru adres girin',
    us6666:'Başarıyla katıldınız!',
    us7777:'Şu anki adres zaten bir süper düğüm!',
    us8888:'Lütfen geçerli bir adres girin',
    us9999:'Değişim başarılı',
    us11111:'Bağlanıyor...',
    us22222:'Yetkilendirme...',
    us33333:'Ödeme yapılıyor...',
    },
    yilou: {
        us44444: 'Benim gelirlerim (dondurulmuş)',
        us55555: 'Güncelleniyor...',
        us66666: 'Alınıyor...',
        us77777: 'Katılım yapılıyor...',
        us88888: 'Değiştiriliyor...',
        us99999: 'Onayla',
        us111111: 'İptal et'
    }
}