module.exports = {

    wakuang1: {
        wodezhanghao: '我的帳號',
        tuijianren: '推薦人',
        fengxiang: '分享',
        dengjijindu:'等級進度',
        dangqiandengji:'當前等級',
        haixu:'仍需',
        shengji:'升級',
        wodedaibi: '我的代幣',
        wodeVCD: '我的 VCD',
        zongji:'總計',
        jiazhi:'價值',
        wodekuangchi: '礦池',
        wodesuanli: '算力',
        yilingqujiangli: '已領取 (U)',
        wodeshouyi: '我的收益',
        jiasushouyi: '加速收益',
        zongji: '總計 (VCD)',
        jiazhi:'價值 (U)',
        zongkuangchi:'總礦池',
        chongzhinengliang: '充值能量',
        wodetuandui: '我的團隊',
        wodezhitui: '直推',
        zhishuxiashu: '下屬',
        dengji:'等級',
        renshu:'業績',
        jine: '金額',
        jianglimingxi: '獎勵明細',
        VCDlingqu: 'VCD 領取',
        shuliang: '數量',
        shijian: '時間',
        dangqianjiage: '當前價格',
    },
    wakuang2: {
        duihuan: '兌換',
        jishijiaoyidaibi: '即時交易代幣',
        yue: '餘額',
        huadianshangxian: '滑點上限',
        jiaoyi: '交易',
    },
    guanwang1: {
        us1: '世界領先的去中心化金融平台',
        us2: '合同',
        us3: '加入聯營公司',
        us4: '我們的目標',
        us5: '選擇交易所交易基金作為您的金融槓桿初始資金較少',
        us6:'·VCD 挖掘',
        us7:'2024 年 8 月',
        us8: '·IM 通訊',
        us9: '2024 年 11 月',
        us10: '·VCD 公鏈測試網',
        us11: '2025 年 5 月',
        us12: 'VCD 主網',
        us13: '2025 年 8 月',
        us14:'VCD 生態',
        us15:'2025 年 12 月',
        us16: 'VCD 通證經濟學',
        us17: '通證分配',
        us18: '總供應量',
        us19: '挖掘',
        us20:'流動性',
        us21:'業績',
        us22: '為何開發 VCD 公鏈',
        us23: '為了讓 VCD 在鏈上執行，這樣會更公開和公平',
        us24: 'VCD 可以在其他公鏈執行嗎',
        us25: '可以實現。然而，由於目前公鏈的機制特性，會被節點套利',
        us26: 'VCD 公鏈和 EVM 公鏈的區別',
        us27: '修改了 GAS 機制，保證節點無法提前排隊，讓金融更公平和安全',
        us28: 'VCD 一直沒在公鏈執行的原因',
        us29: '目前 EVM 的排隊機制，可以讓節點在內網進行排隊，這樣可以 100%套利',
        us30: '聯繫我們',
    },
    guanwang2: {
        us111: '主頁',
        us222: '白皮書',
        us333: '基金會',
        us444: '宣發',
        us555: '採礦',
        us666: '聯繫我們',
        us777: '複製成功',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: '超級節點合夥人招募',
    wodedizhi: '我的地址',
    wodezhanghao: '我的帳號',
    shouquandizhi: '授權地址',
    shifoushichaojijiedian: '是否是超級節點',
    shichaojijiedian:'是超級節點',
    bushichaojijiedian:'不是超級節點',
    dianjijiaru: '點擊加入',
},
    xuanfa: {
    baocuntupian:'保存圖片',
    baocunchenggong:'保存成功',
},
    tishi: {
    us888:'設定成功',
    us999:'請連接錢包',
    us1111:'複製成功',
    us2222:'取消操作',
    us3333:'不能與自己綁定！',
    us4444:'請先連接錢包',
    us5555:'請輸入正確的地址',
    us6666:'已成功加入！',
    us7777:'當前地址已經是超級節點!',
    us8888:'請輸入有效的地址',
    us9999:'兌換成功',
    us11111:'綁定中...',
    us22222:'授權中...',
    us33333:'支付中...',
    },
    yilou: {
        us44444: '我的收益（凍結）',
        us55555: '升級中...',
        us66666: '領取中...',
        us77777: '正在加入...',
        us88888: '兌換中...',
        us99999: '確認',
        us111111: '取消'
    }
}