module.exports = {

    wakuang1: {
        wodezhanghao: 'Il mio account',
        tuijianren: 'Referente',
        fengxiang: 'Condividi',
        dengjijindu:'Progresso del livello',
        dangqiandengji:'Livello corrente',
        haixu:'Ancora necessario',
        shengji:'Aggiornamento',
        wodedaibi: 'Il mio token',
        wodeVCD: 'Il mio VCD',
        zongji:'Totale',
        jiazhi:'Valore',
        wodekuangchi: 'Piscina mineraria',
        wodesuanli: 'Potenza di calcolo',
        yilingqujiangli: 'Già richiesto (U)',
        wodeshouyi: 'I miei guadagni',
        jiasushouyi: 'Guadagni accelerati',
        zongji: 'Totale (VCD)',
        jiazhi:'Valore (U)',
        zongkuangchi:'Totale piscina mineraria',
        chongzhinengliang: 'Ricarica energia',
        wodetuandui: 'La mia squadra',
        wodezhitui: 'Promozione diretta',
        zhishuxiashu: 'Subordinati',
        dengji:'Livello',
        renshu:'Prestazione',
        jine: 'Importo',
        jianglimingxi: 'Dettagli della ricompensa',
        VCDlingqu: 'Richiesta VCD',
        shuliang: 'Quantità',
        shijian: 'Tempo',
        dangqianjiage: 'Prezzo corrente',
    },
    wakuang2: {
        duihuan: 'Scambio',
        jishijiaoyidaibi: 'Token di trading istantaneo',
        yue: 'Saldo',
        huadianshangxian: 'Limite di slippage',
        jiaoyi: 'Transazione',
    },
    guanwang1: {
        us1: "La piattaforma finanziaria decentralizzata leader nel mondo",
        us2: 'Contratto',
        us3: 'Unisciti alla società affiliata',
        us4: 'Il nostro obiettivo',
        us5: 'Scegli fondi scambiati in borsa come leva finanziaria con meno capitale iniziale',
        us6:'·Mining VCD',
        us7:'Agosto 2024',
        us8: '·Comunicazione IM',
        us9: 'Novembre 2024',
        us10: '·Testnet della catena pubblica VCD',
        us11: 'Maggio 2025',
        us12: '·Mainnet VCD',
        us13: 'Agosto 2025',
        us14:'·Ecosistema VCD',
        us15:'Dicembre 2025',
        us16: 'Economia dei token VCD',
        us17: 'Distribuzione dei token',
        us18: 'Offerta totale',
        us19: 'Mining',
        us20:'Liquidità',
        us21:'Prestazione',
        us22: 'Perché sviluppare la catena pubblica VCD',
        us23: 'Per consentire l esecuzione dell VCD sulla catena, che sarà più aperto e giusto',
        us24: 'L VCD può essere eseguito su altre catene pubbliche',
        us25: 'Può essere realizzato. Tuttavia, a causa delle caratteristiche del meccanismo delle attuali catene pubbliche, sarà sfruttato dai nodi',
        us26: 'La differenza tra la catena pubblica VCD e la catena pubblica EVM',
        us27: 'Modificato il meccanismo GAS per garantire che i nodi non possano mettersi in coda in anticipo, rendendo la finanza più giusta e sicura',
        us28: 'Il motivo per cui l VCD non è stato eseguito sulla catena pubblica',
        us29: 'L attuale meccanismo di coda di EVM consente ai nodi di mettersi in coda nella rete interna, che può effettuare arbitraggio al 100%',
        us30: 'Contattaci',
    },
    guanwang2: {
        us111: 'Homepage',
        us222: 'Whitepaper',
        us333: 'Fondazione',
        us444: 'Promozione',
        us555: 'Mining',
        us666: 'Contattaci',
        us777: 'Copia riuscita',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Reclutamento di partner di nodi super',
    wodedizhi: 'Il mio indirizzo',
    wodezhanghao: 'Il mio account',
    shouquandizhi: 'Indirizzo di autorizzazione',
    shifoushichaojijiedian: 'Se è un nodo super',
    shichaojijiedian:'È un nodo super',
    bushichaojijiedian:'Non è un nodo super',
    dianjijiaru: 'Clicca per unirsi',
},
    xuanfa: {
    baocuntupian:'Salva immagine',
    baocunchenggong:'Salvataggio riuscito',
},
    tishi: {
    us888:'Impostazione riuscita',
    us999:'Si prega di collegare il portafoglio',
    us1111:'Copia riuscita',
    us2222:'Annulla operazione',
    us3333:'Non puoi legarti a te stesso!',
    us4444:'Si prega di collegare prima il portafoglio',
    us5555:'Si prega di inserire l indirizzo corretto',
    us6666:'Iscrizione riuscita!',
    us7777:'L indirizzo corrente è già un nodo super!',
    us8888:'Si prega di inserire un indirizzo valido',
    us9999:'Scambio riuscito',
    us11111:'Legame...',
    us22222:'Autorizzazione...',
    us33333:'Pagamento...',
    },
    yilou: {
        us44444: "I miei guadagni (congelati)",
        us55555: "In aggiornamento...",
        us66666: "In ricezione...",
        us77777: "In fase di adesione...",
        us88888: "In fase di scambio...",
        us99999: "Confermare",
        us111111: "Annullare"
    }
}